export const SET_ASREVIEW_VERSION = "SET_ASREVIEW_VERSION";
export const AUTHENTICATION = "AUTHENTICATION";

export const SET_PROJECT = "SET_PROJECT";
export const TOGGLE_HELP_DIALOG = "TOGGLE_HELP_DIALOG";

export const SET_BOOT_DATA = "SET_BOOT_DATA";
export const OAUTH_SERVICES = "OAUTH_SERVICES";

export const MY_PROJECTS = "MY_PROJECTS";
