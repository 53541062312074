import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function DOIIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 130 130">
      <circle
        style={{
          fill: "#fcb425",
        }}
        cx={65}
        cy={65}
        r={64}
      />
      <path
        style={{
          fill: "#231f20",
        }}
        d="M49.82 84.56H37.964v-4.827c-1.204 1.511-4.036 3.052-5.265 3.743-2.151 1.204-5.072 1.987-7.812 1.987-4.43 0-8.374-1.4-11.831-4.447-4.123-3.636-6.06-9.195-6.06-15.188 0-6.095 2.112-10.96 6.338-14.597 3.354-2.893 7.457-5.21 11.81-5.21 2.535 0 5.662.228 7.89 1.304 1.28.614 3.573 2.06 4.93 3.469v-25.42h11.854zM37.7 65.866c0-2.254-.619-4.952-2.207-6.514-1.587-1.588-4.474-2.996-6.727-2.996-2.51 0-4.835 1.825-6.448 3.72-1.306 1.537-1.96 3.906-1.96 5.877 0 1.972.742 4.165 2.048 5.702 1.587 1.895 3.298 3.194 5.833 3.194 2.279 0 4.99-.957 6.552-2.52 1.588-1.561 2.908-4.184 2.908-6.463z"
      />
      <path
        style={{
          fill: "#fff",
        }}
        d="M105.428 25.618c-1.972 0-3.65.691-5.032 2.074-1.358 1.357-2.036 3.022-2.036 4.994s.678 3.649 2.036 5.032c1.382 1.383 3.06 2.074 5.032 2.074 1.997 0 3.674-.678 5.032-2.036 1.383-1.383 2.074-3.073 2.074-5.07 0-1.972-.691-3.637-2.074-4.994-1.383-1.383-3.06-2.074-5.032-2.074zm-31.209 19.89c-7.324 0-12.97 2.283-16.94 6.849-3.277 3.782-4.916 8.118-4.916 13.008 0 5.43 1.626 10.01 4.878 13.739 3.944 4.538 9.475 6.808 16.594 6.808 7.094 0 12.612-2.27 16.556-6.808 3.252-3.729 4.878-8.2 4.878-13.414 0-5.16-1.638-9.605-4.916-13.333-4.02-4.566-9.399-6.85-16.134-6.85zm24.909 1.387v37.634h12.6V46.895h-12.6zM73.835 56.976c2.305 0 4.264.852 5.877 2.554 1.64 1.675 2.459 3.728 2.459 6.16 0 2.458-.807 4.538-2.42 6.24-1.614 1.675-3.585 2.514-5.916 2.514-2.612 0-4.737-1.027-6.376-3.08-1.332-1.649-1.998-3.54-1.998-5.674 0-2.107.666-3.985 1.998-5.633 1.639-2.054 3.764-3.081 6.376-3.081z"
      />
    </SvgIcon>
  );
}

export default DOIIcon;
